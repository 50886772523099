import React, { useState, useEffect } from "react";
import EditBudgetForm from "../components/EditBudgetForm";
import api from "../../../services/api";

const DetalheIndex = ({ location }) => {
    const [entity, setEntity] = useState({});
    const [files, setFiles] = useState([]);

    useEffect(() => {
        const url = window.location.pathname;
        const urlSection = url.split("/");
        const identifier = urlSection[3];

        api.get("quote/" + identifier + "/show", {
            headers: {
                Authorization: "Bearer " + window.localStorage.getItem("session-token"),
            },
        }).then(response => {
            setEntity(response.data);
            setFiles(response?.data?.files);
        });

        api.get("quote/" + identifier + "/log", {
            headers: {
                Authorization: "Bearer " + window.localStorage.getItem("session-token"),
            },
        }).then();
    }, []);

    const Form = () => {
        if (entity) {
            return <EditBudgetForm entity={entity} files={files} />;
        } else {
            return <EditBudgetForm entity={location?.state?.data} files={location?.state?.data?.files} />;
        }
    };

    return <Form />;
};

export default DetalheIndex;
